import './App.scss';
import { StarRating } from './components';
import { useState } from 'react';
import { ReactComponent as BoneIcon } from './components/StarRating/assets/bone.svg';

export default function App() {
  const [exampleOneRating, setExampleOneRating] = useState<number>(5)
  const [exampleTwoRating, setExampleTwoRating] = useState<number>(5)
  return (
      <main className="app">
        <h1 className="app__title">Star Rating Examples</h1>
        <section className="app__example">
          <p>Example 1: Interactive w/callback</p>
          <p>Currently has a <strong>{exampleOneRating}</strong> star rating</p>
          <StarRating
              numStars={5}
              initialRating={exampleOneRating}
              onClick={(newRating) => setExampleOneRating(newRating)}
              theme={{
                colors: {
                  backgroundDefault: '#EEE',
                  backgroundColorActive: 'orangered',
                  backgroundColorHover: 'gold',
                },
                size: 48,
              }}
          />
        </section>
        <section className="app__example">
          <p>Example 2: Interactive w/custom icon</p>
          <p>Currently has a <strong>{exampleTwoRating}</strong> star rating</p>
          <StarRating
              numStars={5}
              initialRating={exampleTwoRating}
              onClick={(newRating) => setExampleTwoRating(newRating)}
              theme={{
                colors: {
                  backgroundDefault: '#EEE',
                  backgroundColorActive: 'cornflowerblue',
                  backgroundColorHover: 'violet',
                },
                size: 55,
              }}
              icon={<BoneIcon/>}
          />
        </section>
        <section className="app__example">
          <p>Example 3: Read Only</p>
          <StarRating
              numStars={5}
              initialRating={5}
              onClick={() => {
              }}
              theme={{
                colors: {
                  backgroundDefault: '#EEE',
                  backgroundColorActive: 'mediumseagreen',
                  backgroundColorHover: 'gold',
                },
                size: 48,
              }}
              readOnly
          />
        </section>
      </main>
  );
}
